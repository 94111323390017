@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', sans-serif; }

section {
  width: 100%;
  min-height: 40vh;

  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 48px;
    color: #202020;
    text-align: center;

    &.main {
      font-size: 60px; } } }

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -56px;
  z-index: -1; }

.about-holder {
  display: flex;
  justify-content: center;
  align-items: center; }

.card {
  padding: 24px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px 1px;
  margin: 160px 0px;
  background-color: rgba(255, 255, 255, 0.75);
  max-width: 400px;
  transition: all 1000ms ease;
  transition-delay: 100ms;

  &.left {
    margin-right: 30%; }

  &.right {
    margin-left: 30%; }

  &.hide {
    opacity: 0.1;
    transform: translateY(50px); }

  &.show {
    opacity: 1;
    transform: translateY(0px); }

  h2 {
    margin: 0 0 16px 0;
    font-size: 38px;
    text-align: center; }

  p {
    margin: 0 0 16px 0;
    font-size: 18px;

    &.tagline {
      font-style: italic; }

    &:last-child {
      margin: 0px; } }

  img {
    width: 100%;
    margin-bottom: 8px; }

  a {
    text-decoration: none;
    font-weight: 700;
    color: black; } }

.about {
  max-width: 500px;

  h2 {
    text-align: left; } }


.contact {
  text-align: center;
  color: white;

  svg {
    width: 32px;
    height: 32px;
    fill: rgba(255, 255, 255, 0.9); }

  a {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0px; } } }


@media screen and ( max-width: 767px ) {
  section {
    h1 {
      font-size: 32px; }

    h1.main {
      font-size: 40px; } }

  .card {
    margin: 40px 15px !important;
    max-width: 100%;

    h2 {
      font-size: 28px; }

    p {
      font-size: 16px; } } }
